/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');
body {
  margin:0;
}
.k-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  border-radius:4px;
  height:40px;
  min-width:40px;
}
